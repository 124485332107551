import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _64c523bc = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _f9c2c488 = () => interopDefault(import('../pages/agreement.vue' /* webpackChunkName: "pages/agreement" */))
const _8daa864c = () => interopDefault(import('../pages/appCompany.vue' /* webpackChunkName: "pages/appCompany" */))
const _00e04c0c = () => interopDefault(import('../pages/certificate/index.vue' /* webpackChunkName: "pages/certificate/index" */))
const _458f8b44 = () => interopDefault(import('../pages/community/index.vue' /* webpackChunkName: "pages/community/index" */))
const _3214d52f = () => interopDefault(import('../pages/company.vue' /* webpackChunkName: "pages/company" */))
const _2ca76ab0 = () => interopDefault(import('../pages/development/index.vue' /* webpackChunkName: "pages/development/index" */))
const _2786ed84 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7c296e2b = () => interopDefault(import('../pages/forget.vue' /* webpackChunkName: "pages/forget" */))
const _031ba660 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _2f0a7cb3 = () => interopDefault(import('../pages/knowledge/index.vue' /* webpackChunkName: "pages/knowledge/index" */))
const _78e1caf5 = () => interopDefault(import('../pages/lecturer/index.vue' /* webpackChunkName: "pages/lecturer/index" */))
const _1da2deca = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _5b7be3e6 = () => interopDefault(import('../pages/permission.vue' /* webpackChunkName: "pages/permission" */))
const _581efe61 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _511d0fb8 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _7f8005a2 = () => interopDefault(import('../pages/tpl.vue' /* webpackChunkName: "pages/tpl" */))
const _66a2c4c4 = () => interopDefault(import('../pages/watch/index.vue' /* webpackChunkName: "pages/watch/index" */))
const _365660bd = () => interopDefault(import('../pages/development/estimate/index.vue' /* webpackChunkName: "pages/development/estimate/index" */))
const _08857c10 = () => interopDefault(import('../pages/development/qualification/index.vue' /* webpackChunkName: "pages/development/qualification/index" */))
const _689eed2c = () => interopDefault(import('../pages/development/study/index.vue' /* webpackChunkName: "pages/development/study/index" */))
const _4aa82e0e = () => interopDefault(import('../pages/home/rank.vue' /* webpackChunkName: "pages/home/rank" */))
const _5b20666e = () => interopDefault(import('../pages/integral/mall.vue' /* webpackChunkName: "pages/integral/mall" */))
const _2900bd93 = () => interopDefault(import('../pages/investigate/view.vue' /* webpackChunkName: "pages/investigate/view" */))
const _32c2ed67 = () => interopDefault(import('../pages/watch/catePanel.vue' /* webpackChunkName: "pages/watch/catePanel" */))
const _4f7693ce = () => interopDefault(import('../pages/watch/course.vue' /* webpackChunkName: "pages/watch/course" */))
const _6881b48e = () => interopDefault(import('../pages/watch/practice.vue' /* webpackChunkName: "pages/watch/practice" */))
const _16c76ffa = () => interopDefault(import('../pages/watch/task.vue' /* webpackChunkName: "pages/watch/task" */))
const _26c407de = () => interopDefault(import('../pages/account/exam/detail.vue' /* webpackChunkName: "pages/account/exam/detail" */))
const _5cb17636 = () => interopDefault(import('../pages/account/exam/examQuestion.vue' /* webpackChunkName: "pages/account/exam/examQuestion" */))
const _26099258 = () => interopDefault(import('../pages/account/exam/examSuccess.vue' /* webpackChunkName: "pages/account/exam/examSuccess" */))
const _56305011 = () => interopDefault(import('../pages/account/exam/record.vue' /* webpackChunkName: "pages/account/exam/record" */))
const _3a57bec7 = () => interopDefault(import('../pages/account/plan/detail.vue' /* webpackChunkName: "pages/account/plan/detail" */))
const _bf8c377e = () => interopDefault(import('../pages/development/estimate/batchEvaluation.vue' /* webpackChunkName: "pages/development/estimate/batchEvaluation" */))
const _77370d3e = () => interopDefault(import('../pages/development/estimate/evaluation.vue' /* webpackChunkName: "pages/development/estimate/evaluation" */))
const _44a84c4e = () => interopDefault(import('../pages/development/estimate/report.vue' /* webpackChunkName: "pages/development/estimate/report" */))
const _e52615e6 = () => interopDefault(import('../pages/account/plan/task/add.vue' /* webpackChunkName: "pages/account/plan/task/add" */))
const _3cc52795 = () => interopDefault(import('../pages/account/plan/task/detail.vue' /* webpackChunkName: "pages/account/plan/task/detail" */))
const _e5231288 = () => interopDefault(import('../pages/knowledge/offcourse/components/AssessAttachment.vue' /* webpackChunkName: "pages/knowledge/offcourse/components/AssessAttachment" */))
const _f72ed2ac = () => interopDefault(import('../pages/account/exam/_id.vue' /* webpackChunkName: "pages/account/exam/_id" */))
const _c83363e0 = () => interopDefault(import('../pages/account/task/_id.vue' /* webpackChunkName: "pages/account/task/_id" */))
const _6735e19c = () => interopDefault(import('../pages/integral/goods/_id.vue' /* webpackChunkName: "pages/integral/goods/_id" */))
const _33a8ddf9 = () => interopDefault(import('../pages/investigate/analyze/_id.vue' /* webpackChunkName: "pages/investigate/analyze/_id" */))
const _24799696 = () => interopDefault(import('../pages/knowledge/course/_id.vue' /* webpackChunkName: "pages/knowledge/course/_id" */))
const _0e1f89d6 = () => interopDefault(import('../pages/knowledge/offcourse/_id.vue' /* webpackChunkName: "pages/knowledge/offcourse/_id" */))
const _d56e5436 = () => interopDefault(import('../pages/knowledge/project/_id.vue' /* webpackChunkName: "pages/knowledge/project/_id" */))
const _720e417d = () => interopDefault(import('../pages/lecturer/view/_id.vue' /* webpackChunkName: "pages/lecturer/view/_id" */))
const _a4cd9af6 = () => interopDefault(import('../pages/live/watch/_watch.vue' /* webpackChunkName: "pages/live/watch/_watch" */))
const _1e9d4d62 = () => interopDefault(import('../pages/redirect/_path.vue' /* webpackChunkName: "pages/redirect/_path" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _64c523bc,
    name: "account___zh"
  }, {
    path: "/agreement",
    component: _f9c2c488,
    name: "agreement___zh"
  }, {
    path: "/appCompany",
    component: _8daa864c,
    name: "appCompany___zh"
  }, {
    path: "/certificate",
    component: _00e04c0c,
    name: "certificate___zh"
  }, {
    path: "/community",
    component: _458f8b44,
    name: "community___zh"
  }, {
    path: "/company",
    component: _3214d52f,
    name: "company___zh"
  }, {
    path: "/development",
    component: _2ca76ab0,
    name: "development___zh"
  }, {
    path: "/en",
    component: _2786ed84,
    name: "index___en"
  }, {
    path: "/forget",
    component: _7c296e2b,
    name: "forget___zh"
  }, {
    path: "/home",
    component: _031ba660,
    name: "home___zh"
  }, {
    path: "/knowledge",
    component: _2f0a7cb3,
    name: "knowledge___zh"
  }, {
    path: "/lecturer",
    component: _78e1caf5,
    name: "lecturer___zh"
  }, {
    path: "/login",
    component: _1da2deca,
    name: "login___zh"
  }, {
    path: "/permission",
    component: _5b7be3e6,
    name: "permission___zh"
  }, {
    path: "/register",
    component: _581efe61,
    children: [{
      path: "",
      component: _511d0fb8,
      name: "register___zh"
    }]
  }, {
    path: "/tpl",
    component: _7f8005a2,
    name: "tpl___zh"
  }, {
    path: "/watch",
    component: _66a2c4c4,
    name: "watch___zh"
  }, {
    path: "/development/estimate",
    component: _365660bd,
    name: "development-estimate___zh"
  }, {
    path: "/development/qualification",
    component: _08857c10,
    name: "development-qualification___zh"
  }, {
    path: "/development/study",
    component: _689eed2c,
    name: "development-study___zh"
  }, {
    path: "/en/account",
    component: _64c523bc,
    name: "account___en"
  }, {
    path: "/en/agreement",
    component: _f9c2c488,
    name: "agreement___en"
  }, {
    path: "/en/appCompany",
    component: _8daa864c,
    name: "appCompany___en"
  }, {
    path: "/en/certificate",
    component: _00e04c0c,
    name: "certificate___en"
  }, {
    path: "/en/community",
    component: _458f8b44,
    name: "community___en"
  }, {
    path: "/en/company",
    component: _3214d52f,
    name: "company___en"
  }, {
    path: "/en/development",
    component: _2ca76ab0,
    name: "development___en"
  }, {
    path: "/en/forget",
    component: _7c296e2b,
    name: "forget___en"
  }, {
    path: "/en/home",
    component: _031ba660,
    name: "home___en"
  }, {
    path: "/en/knowledge",
    component: _2f0a7cb3,
    name: "knowledge___en"
  }, {
    path: "/en/lecturer",
    component: _78e1caf5,
    name: "lecturer___en"
  }, {
    path: "/en/login",
    component: _1da2deca,
    name: "login___en"
  }, {
    path: "/en/permission",
    component: _5b7be3e6,
    name: "permission___en"
  }, {
    path: "/en/register",
    component: _581efe61,
    children: [{
      path: "",
      component: _511d0fb8,
      name: "register___en"
    }]
  }, {
    path: "/en/tpl",
    component: _7f8005a2,
    name: "tpl___en"
  }, {
    path: "/en/watch",
    component: _66a2c4c4,
    name: "watch___en"
  }, {
    path: "/home/rank",
    component: _4aa82e0e,
    name: "home-rank___zh"
  }, {
    path: "/integral/mall",
    component: _5b20666e,
    name: "integral-mall___zh"
  }, {
    path: "/investigate/view",
    component: _2900bd93,
    name: "investigate-view___zh"
  }, {
    path: "/watch/catePanel",
    component: _32c2ed67,
    name: "watch-catePanel___zh"
  }, {
    path: "/watch/course",
    component: _4f7693ce,
    name: "watch-course___zh"
  }, {
    path: "/watch/practice",
    component: _6881b48e,
    name: "watch-practice___zh"
  }, {
    path: "/watch/task",
    component: _16c76ffa,
    name: "watch-task___zh"
  }, {
    path: "/account/exam/detail",
    component: _26c407de,
    name: "account-exam-detail___zh"
  }, {
    path: "/account/exam/examQuestion",
    component: _5cb17636,
    name: "account-exam-examQuestion___zh"
  }, {
    path: "/account/exam/examSuccess",
    component: _26099258,
    name: "account-exam-examSuccess___zh"
  }, {
    path: "/account/exam/record",
    component: _56305011,
    name: "account-exam-record___zh"
  }, {
    path: "/account/plan/detail",
    component: _3a57bec7,
    name: "account-plan-detail___zh"
  }, {
    path: "/development/estimate/batchEvaluation",
    component: _bf8c377e,
    name: "development-estimate-batchEvaluation___zh"
  }, {
    path: "/development/estimate/evaluation",
    component: _77370d3e,
    name: "development-estimate-evaluation___zh"
  }, {
    path: "/development/estimate/report",
    component: _44a84c4e,
    name: "development-estimate-report___zh"
  }, {
    path: "/en/development/estimate",
    component: _365660bd,
    name: "development-estimate___en"
  }, {
    path: "/en/development/qualification",
    component: _08857c10,
    name: "development-qualification___en"
  }, {
    path: "/en/development/study",
    component: _689eed2c,
    name: "development-study___en"
  }, {
    path: "/en/home/rank",
    component: _4aa82e0e,
    name: "home-rank___en"
  }, {
    path: "/en/integral/mall",
    component: _5b20666e,
    name: "integral-mall___en"
  }, {
    path: "/en/investigate/view",
    component: _2900bd93,
    name: "investigate-view___en"
  }, {
    path: "/en/watch/catePanel",
    component: _32c2ed67,
    name: "watch-catePanel___en"
  }, {
    path: "/en/watch/course",
    component: _4f7693ce,
    name: "watch-course___en"
  }, {
    path: "/en/watch/practice",
    component: _6881b48e,
    name: "watch-practice___en"
  }, {
    path: "/en/watch/task",
    component: _16c76ffa,
    name: "watch-task___en"
  }, {
    path: "/account/plan/task/add",
    component: _e52615e6,
    name: "account-plan-task-add___zh"
  }, {
    path: "/account/plan/task/detail",
    component: _3cc52795,
    name: "account-plan-task-detail___zh"
  }, {
    path: "/en/account/exam/detail",
    component: _26c407de,
    name: "account-exam-detail___en"
  }, {
    path: "/en/account/exam/examQuestion",
    component: _5cb17636,
    name: "account-exam-examQuestion___en"
  }, {
    path: "/en/account/exam/examSuccess",
    component: _26099258,
    name: "account-exam-examSuccess___en"
  }, {
    path: "/en/account/exam/record",
    component: _56305011,
    name: "account-exam-record___en"
  }, {
    path: "/en/account/plan/detail",
    component: _3a57bec7,
    name: "account-plan-detail___en"
  }, {
    path: "/en/development/estimate/batchEvaluation",
    component: _bf8c377e,
    name: "development-estimate-batchEvaluation___en"
  }, {
    path: "/en/development/estimate/evaluation",
    component: _77370d3e,
    name: "development-estimate-evaluation___en"
  }, {
    path: "/en/development/estimate/report",
    component: _44a84c4e,
    name: "development-estimate-report___en"
  }, {
    path: "/knowledge/offcourse/components/AssessAttachment",
    component: _e5231288,
    name: "knowledge-offcourse-components-AssessAttachment___zh"
  }, {
    path: "/en/account/plan/task/add",
    component: _e52615e6,
    name: "account-plan-task-add___en"
  }, {
    path: "/en/account/plan/task/detail",
    component: _3cc52795,
    name: "account-plan-task-detail___en"
  }, {
    path: "/en/knowledge/offcourse/components/AssessAttachment",
    component: _e5231288,
    name: "knowledge-offcourse-components-AssessAttachment___en"
  }, {
    path: "/",
    component: _2786ed84,
    name: "index___zh"
  }, {
    path: "/en/account/exam/:id?",
    component: _f72ed2ac,
    name: "account-exam-id___en"
  }, {
    path: "/en/account/task/:id?",
    component: _c83363e0,
    name: "account-task-id___en"
  }, {
    path: "/en/integral/goods/:id?",
    component: _6735e19c,
    name: "integral-goods-id___en"
  }, {
    path: "/en/investigate/analyze/:id?",
    component: _33a8ddf9,
    name: "investigate-analyze-id___en"
  }, {
    path: "/en/knowledge/course/:id?",
    component: _24799696,
    name: "knowledge-course-id___en"
  }, {
    path: "/en/knowledge/offcourse/:id?",
    component: _0e1f89d6,
    name: "knowledge-offcourse-id___en"
  }, {
    path: "/en/knowledge/project/:id?",
    component: _d56e5436,
    name: "knowledge-project-id___en"
  }, {
    path: "/en/lecturer/view/:id?",
    component: _720e417d,
    name: "lecturer-view-id___en"
  }, {
    path: "/en/live/watch/:watch?",
    component: _a4cd9af6,
    name: "live-watch-watch___en"
  }, {
    path: "/account/exam/:id?",
    component: _f72ed2ac,
    name: "account-exam-id___zh"
  }, {
    path: "/account/task/:id?",
    component: _c83363e0,
    name: "account-task-id___zh"
  }, {
    path: "/en/redirect/:path?",
    component: _1e9d4d62,
    name: "redirect-path___en"
  }, {
    path: "/integral/goods/:id?",
    component: _6735e19c,
    name: "integral-goods-id___zh"
  }, {
    path: "/investigate/analyze/:id?",
    component: _33a8ddf9,
    name: "investigate-analyze-id___zh"
  }, {
    path: "/knowledge/course/:id?",
    component: _24799696,
    name: "knowledge-course-id___zh"
  }, {
    path: "/knowledge/offcourse/:id?",
    component: _0e1f89d6,
    name: "knowledge-offcourse-id___zh"
  }, {
    path: "/knowledge/project/:id?",
    component: _d56e5436,
    name: "knowledge-project-id___zh"
  }, {
    path: "/lecturer/view/:id?",
    component: _720e417d,
    name: "lecturer-view-id___zh"
  }, {
    path: "/live/watch/:watch?",
    component: _a4cd9af6,
    name: "live-watch-watch___zh"
  }, {
    path: "/redirect/:path?",
    component: _1e9d4d62,
    name: "redirect-path___zh"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

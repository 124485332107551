import { getUserInfo, logout } from '~/api/user'
import { getWebsite, enumList, getAuthWebsite } from '~/api/system.js'
import { getLogo } from '~/api/home'
import { getSession, getStore, setSession, setStore } from '~/utils/storage'
function toObj(arr) {
  const obj = {}
  for (var i = 0; i < arr.length; i++) {
    obj[arr[i].code] = arr[i].desc
  }
  return obj
}
export default {
  async nuxtServerInit({ dispatch }, context) {
    const { store, req, redirect } = context
    store.commit('GET_TOKEN_SERVER', req)
    // console.log(req.originalUrl, 'req')
    // 判断是否移动端访问
    const head = req.headers || { 'user-agent': '', 'host': '' }
    const host = head['host'] || ''
    const deviceAgent = head['user-agent'].toLowerCase()
    const agentID = deviceAgent.match(/(iphone|ipod|ipad|android)/)
    if (req['originalUrl'].indexOf('/agreement') === -1) {
      if (agentID) {
        if (req['originalUrl'].indexOf('/live/watch') === -1) {
          redirect('/h5')
        } else {
          const url = req.originalUrl.split('/')
          redirect('/h5/#/pages/live/watch/watch?id=' + url[url.length - 1])
        }
      }
    }
    if (!store.state.eid && !store.state.webInfo && !store.state.tokenInfo) {
      await dispatch('GET_WEBINFO', { domain: host })
    }
    if (store.state.tokenInfo) {
      await dispatch('GET_USERINFO')
      await dispatch('GET_AUTHWEBINFO', { domain: host })
    }
  },
  toggleImageVerification({ commit }, status) {
    commit('TOGGLE_IMAGEVERIGICATION', status)
  },
  SET_EID(store, eid) {
    return new Promise((resolve) => {
      store.state.eid = eid
      setStore('SET_EID', eid)
      resolve()
    })
  },
  GET_EID(store) {
    return new Promise((resolve, reject) => {
      const name = getStore('SET_EID')
      store.state.eid = name
      if (name) {
        resolve(name)
      } else {
        reject()
      }
    })
  },
  GET_USERINFO(store, cb) {
    return new Promise((resolve, reject) => {
      console.log('GET_USERINFO')
      getUserInfo({}, store.state.tokenInfo)
        .then((res) => {
          if (res) {
            store.commit('SET_USER', res)
            resolve(res)
            if (cb) {
              cb(store)
            }
          } else {
            if (cb) {
              cb(store, res.code)
            }
            reject(res)
          }
        }).catch((msg) => {
          reject(msg)
          // console.log(msg)
        })
    })
  },
  GET_LOGO(store) {
    return new Promise((resolve, reject) => {
      console.log(store.state.tokenInfo, 'logo')
      getLogo(store.state.tokenInfo).then(res => {
        if (res) {
          store.state.webInfo.logo = res
          // setStore('webInfo', store.state.webInfo)
          resolve(res)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  // type为返回数据类型,obj、arr
  GetOpts({ commit }, { enumName, type = 'arr' }) {
    const sessData = getSession(enumName)
    if (sessData) {
      if (type === 'obj') {
        return toObj(JSON.parse(sessData))
      }
      return JSON.parse(sessData)
    }
    return new Promise((resolve, reject) => {
      enumList(enumName).then(response => {
        if (Response) {
          let resData = response
          setSession(enumName, response)
          if (type === 'obj') {
            resData = toObj(response)
          }
          resolve(resData)
        } else {
          reject(response.msg)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  GET_WEBINFO(store, params) {
    console.log('GET_WEBINFO', params)
    // 获取网站信息
    return new Promise((resolve, reject) => {
      getWebsite(store.state.tokenInfo, params).then(res => {
        if (res) {
          res.logo = ''
          store.state.webInfo = res

          store.state.isShowSwitchCompany = !res.eid

          store.state.clientData.name = res.websiteTitle
        }
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  GET_AUTHWEBINFO(store, params) {
    // 获取网站信息
    return new Promise((resolve, reject) => {
      getAuthWebsite(store.state.tokenInfo, params)
        .then(res => {
          if (res) {
            res.logo = ''
            store.state.webInfo = res
            store.state.clientData.name = res.websiteTitle
          }
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  REDIRECT_LOGIN(store) {
    // 退出登录
    return new Promise((resolve, reject) => {
      logout().then(res => {
        if (res) {
          store.commit('SIGN_OUT')
          resolve(res)
        }
      }).catch(error => {
        reject(error)
      })
    })
  }
}

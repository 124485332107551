

import customLink from '@/components/custom/link.vue'
import { exportRecordAllDelete, exportRecordDelete, exportRecordGetFileDownloadUrl, exportRecordPage, getAllNoticeCount } from '~/api/home'
import { navView } from '~/api/home.js'

// img
import approval from 'assets/icons/approval.svg'
import certificate from 'assets/icons/certificate.svg'
import contribute from 'assets/icons/contribute.svg'
import admin from 'assets/icons/crm.svg'
import lang from 'assets/icons/lang.svg'
import logout from 'assets/icons/logout.svg'
import menu from 'assets/icons/menu.svg'
import more from 'assets/icons/more.svg'
import sign from 'assets/icons/sign.svg'
import notice from 'assets/message.svg'
//
import cookies from '@/utils/cookies'
import { getSize } from '@/utils/utils'
import { mapGetters } from 'vuex'

export default {
  components: {
    customLink
  },
  data() {
    return {
      componentInfo: {
        contentList: [],
        userEntLogo: ''
      },
      showNum: 5,
      map: {
        notice: notice,
        admin: admin,
        sign: sign,
        logout: logout,
        menu: menu,
        lang: lang,
        more: more,
        contribute: contribute,
        approval: approval,
        certificate: certificate
      },
      opts: {
        downList: []
      },
      num: {
        count: 0,
        announcementCount: 0,
        userMsgNoticeCount: 0
      },
      page: {
        beginPageIndex: 1,
        pageCurrent: 1,
        endPageIndex: 8,
        pageSize: 20,
        totalCount: 0,
        totalPage: 0
      },
      // InformationList: this.$store.state.InformationList.list,

      isTeacher: false,
      isNow: '',
      seekText: '',
      isMore: false,
      isClass: false,
      isSeek: false,
      classList: [],
      time: 0,
      userType: 1,
      teacherRoutreList: [],
      routreList: [],
      communityRoutreList: []
    }
  },

  computed: {
    ...mapGetters(['webInfo']),
    isShowSwitchCompany() {
      return this.$store.state.isShowSwitchCompany
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    tokenInfo() {
      return this.$store.state.tokenInfo || ''
    }
  },
  watch: {
    $route: {
      handler(to) {
        this.$nextTick(() => {
          this.getHightNav(to)
        })
      }, immediate: true
    }
  },
  mounted() {
    this.getNavView()
    this.getNoticeCount()
    // 新页面打开
  },
  methods: {
    getNavView() {
      navView().then(res => {
        if (res) {
          this.componentInfo = res
          this.componentInfo.contentList = res.contentList.filter(ite => ite.statusId === 1) || []
          this.getHightNav(this.$route)
        }
      })
    },
    getHightNav(to) {
      const fullPath = to.fullPath
      this.componentInfo && this.componentInfo.contentList && this.componentInfo.contentList.forEach((ite, i) => {
        if (fullPath.indexOf(ite.targetAddr) === 0) {
          this.$set(this.componentInfo, 'activeIndex', i)
        }
      })
    },
    showheadMoreBg() {
      this.isMore = true
    },
    hideheadMoreBg() {
      this.isMore = false
    },
    handleNavigate(to) {
      // let url = ''
      // if(to.targetType !== 90 || to.targetType !== 99) {

      // }
      // console.log(to, 'to')
      return to.targetAAddr
    },

    getSize,
    handleOpenPoint() {
      let flag = false
      if (this.$route.path !== '/account') {
        flag = true
      } else {
        if (this.$route.query.change !== '3') {
          flag = true
        } else {
          if (this.$route.query.recordType) {
            if (this.$route.query.recordType !== '1') {
              flag = true
            }
          }
        }
      }
      if (flag) {
        this.$router.push({
          path: '/redirect/account',
          params: { path: 'account' },
          query: {
            change: 3,
            recordType: 1
          }
        })
      }
    },
    handleOpenCertificate() {
      let flag = false
      if (this.$route.path !== '/account') {
        flag = true
      } else {
        if (this.$route.query.change !== '3') {
          flag = true
        } else {
          if (this.$route.query.recordType) {
            if (this.$route.query.recordType !== '2') {
              flag = true
            }
          }
        }
      }
      if (flag) {
        this.$router.push({
          path: '/redirect/account',
          params: { path: 'account' },
          query: {
            change: 3,
            recordType: 2
          }
        })
      }
    },
    load() {
      if (this.page.totalPage > this.page.pageCurrent) {
        this.page.pageCurrent++
        this.getDownList()
      }
    },
    getFirstDown() {
      this.page.pageCurrent = 1
      this.opts.downList = []
      this.getDownList()
    },
    handleCloseAllDown() {
      this.$confirm(this.$t('sureClearLog'), this.$t('tip'), {}).then(() => {
        exportRecordAllDelete().then(res => {
          if (res) {
            this.$message.success(res)
            this.getFirstDown()
          }
        })
      })
    },
    handleCloseDown(row) {
      exportRecordDelete({ id: row.id }).then(res => {
        if (res) {
          this.$message.success(res)
          this.getFirstDown()
        }
      })
    },
    getDownUrl(row) {
      exportRecordGetFileDownloadUrl({ id: row.id }).then(res => {
        if (res) {
          window.open(res)
        }
      })
    },
    getDownList() {
      exportRecordPage({}, this.page.pageCurrent, this.page.pageSize).then(res => {
        res.list.forEach(el => {
          this.opts.downList.push(el)
        })
        // console.log(res.list, '导出目录')
        this.page.pageCurrent = res.pageCurrent
        this.page.pageSize = res.pageSize
        this.page.totalCount = res.totalCount
        this.page.totalPage = res.totalPage
      })
    },
    handleOpenNotice(type) {
      this.$router.push({
        path: '/redirect/account',
        params: { path: 'account' },
        query: {
          change: type
        }
      })
    },
    handleOpenAdmin() {
      const path = this.$router.resolve({
        path: '/crm/'
      })
      window.open(path.href, '_blank')
    },
    getNoticeCount() {
      getAllNoticeCount().then(res => {
        this.num = res
      })
    },
    handleCommand(command) {
      switch (command) {
        case 'change':
          this.$router.push({
            path: '/company'
          })
          break
        case 'point':
          this.handleOpenPoint()
          break
        case 'certificate':
          this.handleOpenCertificate()
          break
        case 'logout':
          this.$store.dispatch('REDIRECT_LOGIN').then(() => {
            window.location.reload()
          })
          break
        case 'task':
          this.$router.push({
            path: '/redirect/account',
            params: { path: 'account' },
            query: {
              change: 1
            }
          })
          break
        case 'lang':
          // eslint-disable-next-line no-case-declarations
          const lang = cookies.getInClient('lang') === 'en' ? 'zh' : 'en'
          this.$store.commit('SET_LANG', lang)
          this.$i18n.locale = lang
          break
        case 'card':
          this.$router.push({
            path: '/redirect/account',
            params: { path: 'account' },
            query: {
              change: 3
            }
          })
          break
      }
    },
    isUserType() {
      let val = this.userInfo || this.$store.state.userInfo
      if (!val) {
        this.$store.dispatch('GET_USERINFO').then((res) => {
          this.userInfo = res
          val = res
        })
      }
      if (val && val.userType === 3) {
        const d = {
          pathName: 'account-organization-vod',
          name: '机构中心'
        }
        const is = this.routreList.find(item => {
          return item.pathName === d.pathName
        })
        if (!is) {
          this.routreList.unshift(d)
        }
      }
    },
    seek(e) {
      if (this.isSeek) {
        this.isSeek = false
        if (!this.seekText) {
          this.$message({
            showClose: true,
            message: '请输入搜索关键词',
            type: 'error'
          })
          return
        }
        const path = this.$route.path
        let type = 1
        if (path.indexOf('live') !== -1) {
          type = 2
        }
        if (path.indexOf('resource') !== -1) {
          type = 4
        }
        if (path.indexOf('exam') !== -1) {
          type = 5
        }
        if (path.indexOf('blog') !== -1) {
          type = 6
        }
        if (path.indexOf('question') !== -1) {
          type = 7
        }
        if (path.indexOf('/info') !== -1) {
          type = 8
        }
        this.$router.push({
          name: 'search-str',
          params: { str: this.seekText },
          query: { type }
        })
      } else {
        this.isSeek = true
      }
    },
    hideSeek() {
      this.isSeek = false
    },
    hideClass() {
      this.isClass = false
    },
    showClass() {
      this.isClass = true
    },
    toMsg() {
      // 加一个时间戳 让每次点击站内信按钮url都会变化 一变化就回到站内信列表
      this.$router.push({
        name: 'account-message',
        query: { num: new Date().getTime() }
      })
    },
    login() {
      this.$store.commit('SET_TEMPORARYURL')
      this.$router.push({ name: 'login' })
    }
  }
}

